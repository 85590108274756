import { faBell } from "@fortawesome/free-solid-svg-icons";
import {
  AsideLinksType,
  ProfileLinksType,
} from "Interfaces/Components/Shared/AsideInterface";

const academy_id = localStorage.getItem("academyId");

export const ASIDE_LINKS: AsideLinksType[] = [
  ...(academy_id
    ? [
        {
          icon: "dashboard",
          text: "Dashboard",
          link: `/academies/${academy_id}/dashboard`,
          resourceType: "Academy",
          action: "dashboard",
        },
      ]
    : []),
  { icon: "batch", text: "Batches", link: "/batches", resourceType: "Batch" },
  { icon: "player", text: "Players", link: "/players", resourceType: "Player" },
  { icon: "coach", text: "Coaches", link: "/coaches", resourceType: "Coach" },
  {
    icon: "survey",
    text: "Survey Questions",
    link: "/survey-questions",
    resourceType: "SurveyQuestion",
  },
  {
    icon: "academy",
    text: "Academy Portal",
    link: "/academies",
    resourceType: "Academy",
  },
  {
    icon: "drill",
    text: "Global Videos",
    link: "/drills",
    resourceType: "Drill",
  },
  {
    icon: "sport",
    text: "Sports",
    link: "/sports",
    resourceType: "MasterSport",
  },
  {
    icon: "report",
    text: "Reports",
    link: "/reports",
    resourceType: "report",
  },
  {
    icon: "video",
    text: "Internal Videos",
    link: "/featured-videos",
    resourceType: "featured_asset",
  },
  {
    icon: "video",
    text: "Promotional Assets",
    link: "/public-asset",
    resourceType: "public_asset",
  },
];

export const PROFILE_LINKS: ProfileLinksType[] = [
  {
    icon: faBell,
  },
  // {
  //   icon: faUser,
  // },
  // {
  //   icon: faGear,
  // },
];

export const Enable_Nav: string[] = [
  "Dashboard",
  "Players",
  "Sports",
  "Coaches",
  "Batches",
  "Academy Portal",
  "Survey Questions",
  "Global Videos",
  "Reports",
  "Internal Videos",
  "Promotional Assets",
];
