import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAppSelector } from "App/hooks";
import { fetchDashboardDetails } from "Api/Pages/DashboardApi";
import { DashboardApiResponse } from "Interfaces/Components/Shared/DashboardInterface";
import Card from "./Card";
import PlayerActivity from "./PlayerActivity";
import TopPlayers from "./TopPlayers";
import VideoCard from "./VideoCard";

import "react-datepicker/dist/react-datepicker.css";

import CoachActivity from "./CoachActivity";
const Dashboard = () => {
  const { id } = useParams();
  const {
    userDetails: { academy_name },
  } = useAppSelector((state) => state.loginReducer);

  const { startDate, endDate } = useMemo(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    return {
      startDate: formatDate(startOfMonth),
      endDate: formatDate(today),
    };
  }, []);

  const { data } = useQuery<DashboardApiResponse>(
    ["DasboardResponse", id, startDate, endDate],
    async () => {
      return fetchDashboardDetails({
        id,
        user_schedule_start_date: endDate,
        user_schedule_end_date: endDate,
        assets_start_date: startDate,
        assets_end_date: endDate,
        top_players_start_date: startDate,
        top_players_end_date: endDate,
      });
    }
  );

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center px-4">
        <h2 className="fs-md mb-0 fw-medium"> {academy_name} Dashboard</h2>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body flex-1 scroll-y p-4">
        <div className="d-flex justify-content-between mb-3">
          <section className="dashboard section">
            <div className="row gx-3">
              <div className="col-lg-8">
                <div className="row ">
                  <Card
                    totalPlayers={data?.total_players_count}
                    totalCoaches={data?.total_coaches_count}
                  ></Card>
                  <div className="col-lg-12 mt-3">
                    {data?.top_players && (
                      <TopPlayers
                        topPlayers={data.top_players}
                        topPlayerStartDate={startDate}
                        topPlayerEndDate={endDate}
                      ></TopPlayers>
                    )}
                  </div>
                  <div className="col-lg-12 mt-3">
                    {data && (
                      <VideoCard
                        assetStartDate={startDate}
                        assetEndDate={endDate}
                        mostRatedDrill={data.most_rated_drill_asset}
                        mostRatedFeatured={data.most_rated_featured_asset}
                        hightestViewedDrill={data.most_viewed_drill_asset}
                        hightestViewedFeatured={data.most_viewed_featured_asset}
                      ></VideoCard>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {data?.player_schedules_count && (
                  <PlayerActivity
                    playerSchedule={data.player_schedules_count}
                    todayDate={endDate}
                  />
                )}
                <div className="mt-3">
                  {data?.player_schedules_count && (
                    <CoachActivity
                      coachSchedule={data.coach_schedules_count}
                      todayDate={endDate}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
